import React, {useRef, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoffee} from "@fortawesome/free-solid-svg-icons";

const ContactForm: React.FC = () => {
    const [formValues, setFormValues] = useState({
        isLookingForCoffee: false,
        isLookingForWork: false,
        fullname: "",
        email: "",
        phoneNumber: "",
        bericht: "",
        isPartner: false,
        link: ""
    });

    const [cv, setCv] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const inputFileRef = useRef();

    const handleUpload = async (event) => {
        if (event.target.files && event.target.files.length !== 0) {
            setCv(event.target.files?.[0]!);
        } else if (cv) {
            setCv(null)
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        if (name === 'isLookingForWork') {
            handleLookingForWork(checked);
        } else if (name === 'isPartner') {
            handlePartner(checked);
        } else if (name === 'isLookingForCoffee') {
            handleCoffee(checked);
        }
        if (cv) setCv(null);
    };


    const handleLookingForWork = (checked) => {
        setFormValues({
            ...formValues,
            isLookingForWork: checked,
            link: null,
            isPartner: false,
            isLookingForCoffee: false
        });
    };

    const handlePartner = (checked) => {
        setFormValues({
            ...formValues,
            isLookingForWork: false,
            link: null,
            isPartner: checked,
            isLookingForCoffee: false
        });
    };

    const handleCoffee = (checked) => {
        setFormValues({
            ...formValues,
            isLookingForWork: false,
            link: null,
            isPartner: false,
            isLookingForCoffee: checked
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (isSending) return;

        if (!formValues.fullname) {
            setErrorMessage('Vul uw volledige naam in');
        } else if (!formValues.email && !formValues.phoneNumber) {
            setErrorMessage('Vul uw e-mailadres of telefoonnummer in');
        } else if (!formValues.isLookingForWork && !formValues.isPartner && !formValues.isLookingForCoffee) {
            setErrorMessage('Selecteer ten minste één selectievakje');
        } else {
            setErrorMessage('');
            setIsSending(true)

            const promise = new Promise(async (resolve, reject) => {
                try {
                    if (cv !== null) {
                        const result1 = await fetch(`/api/signed-url?file=${formValues.fullname}&fileType=${cv.type}`, {method: "GET"});
                        if (!result1.ok) return reject(null);

                        const {url} = await result1.json();
                        const result2 = await fetch(url, {
                            method: "PUT",
                            body: cv as any,
                            headers: {"Content-Type": cv.type}
                        });
                        if (!result2.ok) return reject(null);

                        const result3 = await fetch(`/api/upload-url?key=${formValues.fullname}`);
                        if (!result3.ok) return reject(null);

                        const {link} = await result3.json();
                        setFormValues({...formValues, link: link})

                        const result4 = await fetch("/api/mail", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({...formValues, link: link}),
                        })
                        if (!result4.ok) {
                            return reject(null);
                        }
                    } else {
                        const result4 = await fetch("/api/mail", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(formValues),
                        })
                        if (!result4.ok) {
                            return reject(null);
                        }
                    }
                    return resolve(null);
                } catch (ex) {
                    return reject(null);
                }
            }).finally(() => {
                setFormValues({
                    link: "",
                    isPartner: false,
                    isLookingForCoffee: false,
                    isLookingForWork: false,
                    fullname: "",
                    bericht: "",
                    phoneNumber: "",
                    email: ''
                });
                setIsSending(false);
                if (inputFileRef.current)
                    (inputFileRef.current as any).value = null;
            });

            toast.promise(promise, {
                pending: "Email verzenden...",
                success: "Email succesvol verzonden!",
                error: "Er is een fout opgetreden, probeer het later opnieuw...",
            });
        }
    };

    return (
        <>
            <ToastContainer position="bottom-left"/>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input
                                className="form-control bdr-16"
                                type="text"
                                name="fullname"
                                value={formValues.fullname}
                                onChange={(e) =>
                                    setFormValues({...formValues, fullname: e.target.value})
                                }
                                placeholder="Naam & Voornaam*"
                                control-id='ControlID-1'
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input
                                className="form-control bdr-16"
                                type="email"
                                name="email"
                                value={formValues.email}
                                onChange={(e) =>
                                    setFormValues({...formValues, email: e.target.value})
                                }
                                placeholder="E-mailadres*"
                                control-id='ControlID-2'
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input
                                className="form-control bdr-16"
                                type="tel"
                                name="phoneNumber"
                                value={formValues.phoneNumber}
                                onChange={(e) =>
                                    setFormValues({...formValues, phoneNumber: e.target.value})
                                }
                                placeholder="Telefoonnummer*"
                                control-id='ControlID-3'
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                                                <textarea
                                                    className="form-control bdr-16"
                                                    name="bericht"
                                                    value={formValues.bericht}
                                                    onChange={(e) =>
                                                        setFormValues({...formValues, bericht: e.target.value})
                                                    }
                                                    placeholder="Bericht"
                                                    control-id='ControlID-4'
                                                />
                        </div>
                    </div>
                    <div className="col-lg-12 mb-30 d-flex flex-column">
                        <div>
                            <label onClick={() => handleLookingForWork(formValues.isLookingForWork)}
                                   className="form-check-label pointer">
                                <input
                                    className="form-check-input mr-10 custom-checkbox"
                                    type="checkbox"
                                    name="isLookingForWork"
                                    checked={formValues.isLookingForWork}
                                    onChange={handleCheckboxChange}
                                    control-id='ControlID-5'
                                />Ik ben op zoek naar werk
                            </label>
                        </div>
                        {formValues.isLookingForWork && (
                            <input
                                ref={inputFileRef}
                                className='mb-10 mt-10'
                                type="file"
                                accept=".pdf,.doc,.docx"
                                placeholder="Upload CV"
                                onChange={handleUpload}
                                control-id='ControlID-9'
                            />
                        )}
                        <div className='mt-5'>

                            <label onClick={() => handlePartner(formValues.isPartner)}
                                   className="form-check-label pointer">
                                <input
                                    className="form-check-input mr-10 custom-checkbox"
                                    type="checkbox"
                                    name="isPartner"
                                    checked={formValues.isPartner}
                                    onChange={handleCheckboxChange}
                                    control-id='ControlID-6'
                                />Ik wil partner worden
                            </label>
                        </div>
                        <div className='mt-5'>
                            <label onClick={() => handleCoffee(formValues.isLookingForCoffee)}
                                   className="form-check-label pointer">
                                <input
                                    className="form-check-input mr-10 custom-checkbox"
                                    type="checkbox"
                                    name="isLookingForCoffee"
                                    checked={formValues.isLookingForCoffee}
                                    onChange={handleCheckboxChange}
                                    control-id='ControlID-7'
                                />Ik wil graag langskomen voor een koffietje en jullie beter leren kennen{" "}
                                <FontAwesomeIcon className='color-green-900' icon={faCoffee}/>
                            </label>
                        </div>
                    </div>
                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage}</div>
                    )}
                    <div className="col-lg-12 d-flex justify-content-center">
                        <button className="btn btn-black hover-up" type="submit" control-id='ControlID-8'>
                            Verstuur bericht
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ContactForm;